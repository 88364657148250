<template>
    <div class="main">
        <!-- <div class="main-tab">
            <p :class="{active:item.id == curTab}" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{item.label}}</p>
            <div v-for="item in sessionsList" :key="item.id" :class="{active : params.contestSessionsId == item.id}" @click="changeSessions(item)">第{{num[item.contestSessions]}}场</div>
        </div>
        <div class="main-table">
            <el-table header-align="center"
            header-cell-class-name="message-header_row_style" 
            :data="list" style="width: 100%"
            >
                <el-table-column label="排名" align="center">
                    <template slot-scope="scope">
                        <div class="rank-num">
                            <img :src="rankImg[scope.row.totalRank - 1]" alt="" v-if="scope.row.totalRank < 4">
                            <p v-else>{{scope.row.totalRank}}</p>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column :align="item.align || 'center'" :show-overflow-tooltip="true" v-for="item in columns" :key="item.prop" :prop="item.prop" :width="item.width" :label="item.label">
                </el-table-column>
                <el-table-column label="为我加热" align="center" v-if="curTab == 2">
                    <template slot-scope="scope">
                        <div class="heat" @click="heat(scope.row)">
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog
            title=""
            :visible.sync="dialogVisible"
            width="30%"
            :show-close="false"
            :close-on-click-modal="false"
            class="heat-dialog"
            >
            <div slot="title" class="heat-tit">
                <p>为我加热</p>
                <p class="close" @click="dialogVisible = false">X</p>
            </div>
            <div class="heat-cont">
                <p>为第一幼儿园加热</p>
                <p>邀请您的好友和孩子家长一起为您的园所加热，赢取热力榜冠军吧。邀请方式已经发送到您的手机上，请您查收！</p>
            </div>
            <div slot="footer" class="heat-footer">
                <p @click="dialogVisible = false">确 定</p>
            </div>
        </el-dialog> -->
        <div class="main-tit">
            全国幼儿足球小小运动会(重庆站)
        </div>
        <div class="main-img">
            <img src="../../assets/img/sports/Pic_PH.png" alt="">
        </div>
    </div>
</template>
<script>
export default {
    name:'sportsRank',
    data(){
        return {
            tabList:[
                {
                    label:'实力榜',
                    id:1
                },
            ],
            curTab:1,
            columns: [
                {
                    prop: 'schoolName',
                    label: '园所名称',
                    align:'center',
                },
                {
                    prop: 'totalIntegral',
                    label: '积分',
                    align:'center',
                },
            ],
            rankImg:[
                require('../../assets/img/sports/Ic_Pm_TheF.png'),
                require('../../assets/img/sports/Ic_Pm_TheS.png'),
                require('../../assets/img/sports/Ic_Pm_TheT.png'),
            ],
            list:[],
            dialogVisible:false,
            sessionsList:[],
            params:{
                "contestAreaId": '', //赛区id
                "contestSessions": '',   //场次数
                "contestSessionsId": ''  //场次id
            },
            num:['零', '一', '二', '三', '四', '五', '六', '七', '八', '九','十','十一','十二'],
        }
    },
    watch:{
        "$store.state.spotrsindex"(newval){
            if(newval){
                this.params.contestAreaId = this.$store.state.spotrsindex;
                this.getSessionsList();
            }
        }
    },
    mounted(){
        this.params.contestAreaId = this.$store.state.spotrsindex;
        if(this.params.contestAreaId){
            //获取场次列表
            this.getSessionsList();
        }
        
        // this.getAreaRanking();
    },
    methods:{
        getSessionsList(){
            this.api.sports.sessionsList(this.params.contestAreaId).then((res)=>{
                if(res.flag){
                    this.sessionsList = res.data;
                    this.params.contestSessions = res.data[0].contestSessions;
                    this.params.contestSessionsId = res.data[0].id;
                    //请求成功后调用
                    this.getAreaRanking(this.params.contestSessionsId);
                }else{
                    this.$notice({
                      message: res.message,
                    }).isShow();
                }
            });
        },
        heat(data){
            console.log(data,'d')
            this.dialogVisible = true;
        },
        changeTab(data){    //切换榜单
            this.curTab = data.id;
        },
        getAreaRanking(id){   //获取实力榜数据
            this.api.sports.areaRanking(id).then((res)=>{
                if(res.flag){
                    this.columns = [
                        {
                            prop: 'schoolName',
                            label: '园所名称',
                            align:'center',
                        },
                        {
                            prop: 'totalIntegral',
                            label: '积分',
                            align:'center',
                        },
                    ];
                    this.list = [];
                    if(res.data.length){
                        let projectList = res.data[0].contestAchievementDtoList;
                        let list = projectList.length && projectList.map(item => {
                            // let prop = item.achievementType == 1 ? ((item.hourValues && item.hourValues + ":") + (item.minuteValues && item.minuteValues + ":") + item.secondValues) : item.numberValues;
                            return {
                                prop: item.contestProjectId + 'contestProjecVal',
                                label: item.contestProjectName,
                                align:'center',
                            }
                        });
                        if(list.length){
                            this.columns = this.columns.concat(list);
                        }

                        res.data.forEach(item => {
                            item.contestAchievementDtoList.forEach((sItem)=>{
                                let time = '';
                                if(sItem.hourValues){
                                    time = ((sItem.hourValues && sItem.hourValues + ":") + (sItem.minuteValues && sItem.minuteValues + ":") + sItem.secondValues);
                                }else if(sItem.minuteValues){
                                    time = ((sItem.minuteValues && sItem.minuteValues + ":") + sItem.secondValues) ;
                                }else{
                                    time=sItem.secondValues;
                                }
                                let prop = sItem.achievementType == 1 ? time : sItem.numberValues;
                                item[sItem.contestProjectId + 'contestProjecVal'] = prop;
                            })
                        });
                        this.list = res.data;
                    }
                    
                }else{
                    this.$notice({
                      message: res.message,
                    }).isShow();
                }
                
            });
        },
        changeSessions(data){
            if(data.id === this.params.contestSessionsId) return;
            this.params.contestSessions = data.contestSessions;
            this.params.contestSessionsId = data.id;
            this.getAreaRanking(this.params.contestSessionsId);
        },
    }
}
</script>
<style lang="scss" scoped>
.main{
    margin:0 auto;
    padding:28px 20px 40px 20px;
    width:1394px;
    background:#FFFFFF;
    .main-tab{
        @include flex(row, flex-start,center);
        >p{
            padding-left: 44px;  
            width:76px;
            height:38px;
            line-height: 38px;
            border: 1px solid #BB3037;
            border-radius: 4px;
            color: #BB3037;
            margin-right:20px;
            font-size:20px;
            font-weight: bold;
            cursor:pointer;
        }
        >p.active{
            color: #FFFFFF;
        }
        >p:nth-child(1){
            background: url('../../assets/img/sports/Ic_SLB_UnC.png') no-repeat 14px center;
            background-size: 20px 20px;
        }
        >p:nth-child(1).active{
            background:#BB3037 url('../../assets/img/sports/Ic_SLB_C.png') no-repeat 14px center;
            background-size: 20px 20px;
        }
        >p:nth-child(2){
            background: url('../../assets/img/sports/Ic_RLB_UnC.png') no-repeat 14px center;
            background-size: 20px 20px;
        }
        >p:nth-child(2).active{
            background:#BB3037 url('../../assets/img/sports/Ic_RLB_C.png') no-repeat 14px center;
            background-size: 20px 20px;
        }
        >div{
            font-size: 18px;
            font-weight: 400;
            color: #AAAAAA;
            margin-right:20px;
            cursor:pointer;
        }
        >div.active{
            color: #BB3037;
        }
    }
    .main-table{
        margin-top:20px;
        box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
        border-radius: 4px;
        overflow:hidden;
        .rank-num{
            text-align:center;
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            opacity: 0.9;
        }
        .heat{
            margin:0 auto;
            width:29px;
            height:34px;
            background: url('../../assets/img/sports/Ic_WWJR.png') no-repeat;
            background-size: 29px 34px;
            cursor:pointer;
        }
        .heat:hover{
            background: url('../../assets/img/sports/Ic_WWJR_H.png') no-repeat;
            background-size: 29px 34px;
        }
    }
    .heat-dialog{
        font-size:18px;
        /deep/.el-dialog{
            border-radius: 10px 10px 0 0;
        }
        /deep/.el-dialog__header{
            padding:0;
        }
        .heat-tit{
            height:50px;
            line-height:50px;
            background: #BA3138;
            border-radius: 10px 10px 0 0;
            color:#FFFFFF;
            position: relative;
            text-align: center;
            font-weight: bold;

            >p:nth-child(2){
                position: absolute;
                right:20px;
                top:1px;
                font-size:18px;
                cursor:pointer;
            }
        }
        .heat-cont{
            color: #333333;
            >p:nth-child(1){
                text-align: center;
                font-size: 18px;
                font-weight: bold;
            }
            >p:nth-child(2){
                margin-top:24px;
                padding:0 10px;
                font-size: 16px;
                line-height: 24px;
            }
        }
        .heat-footer{
            >p{
                margin:20px auto 10px auto;
                width: 140px;
                height: 38px;
                background: linear-gradient(232deg, #BA3037, #F0525A);
                box-shadow: 0px 3px 6px 0px rgba(255, 67, 77, 0.33);
                border-radius: 6px;
                color:#FFFFFF;
                line-height:38px;
                text-align: center;
                font-size: 18px;
                cursor: pointer;
            }
            >p:hover{
                background: linear-gradient(232deg, #cf565b, #f46e73);
            }
        }
    }
    .main-tit{
        text-align:center;
        font-size: 28px;
        font-weight: bold;
        color: #333333;
    }
    .main-img{
        margin-top:10px;
        >img{
            width:100%;
            height:495px;
        }
    }
}
</style>
<style>
.message-header_row_style{
    background:#BB3037 !important;
    color:#FFFFFF;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    opacity: 0.9;
}
</style>